<template>

	<div class="container">
		<div class="search">
			<el-form :inline="true" class="demo-form-inline">
				<el-form-item label="操作数据">
					<el-select v-model="search.os_type" placeholder="---选择操作系统---">
						<el-option label="安卓" :value="1" />
						<el-option label="IOS" :value="2" />
					</el-select>
				</el-form-item>
				<el-form-item label="状态">
					<el-select v-model="search.status" placeholder="---选择发布状态---">
						<el-option label="测试" :value="1" />
						<el-option label="发布" :value="2" />
						<el-option label="撤回" :value="3" />
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="getList">查询</el-button>
					<el-button type="primary"><router-link :to='"/iot/rn/upload?panel_type="+search.panel_type'>上传</router-link></el-button>
				</el-form-item>
			</el-form>

		</div>
		<el-table :data="tableData" stripe>
			<el-table-column prop="os_type" label="系统">
				<template #default="scope">
					{{scope.row.os_type == 1? "安卓":"IOS" }}
				</template>
			</el-table-column>
			<el-table-column prop="panel_name" label="面板名称" />
			<el-table-column prop="panel_version" label="版本" />
			<el-table-column prop="app_version" label="最小APP版本" />

			<el-table-column prop="status" label="状态">
				<template #default="scope">
					<el-tag v-if="scope.row.status == 1" type="warning">测试</el-tag>
					<el-tag v-if="scope.row.status == 2" type="success">发布</el-tag>
					<el-tag v-if="scope.row.status == 3" type="info">撤回</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="create_time" label="创建时间">
				<template #default="scope">
					{{timeToDate(scope.row.create_time)}}
				</template>
			</el-table-column>
			<el-table-column prop="update_time" label="修改时间">
				<template #default="scope">
					{{timeToDate(scope.row.update_time)}}
				</template>
			</el-table-column>
			<el-table-column label="操作">
				<template #default="scope">
					<el-tooltip class="item" effect="dark" content="查看KEY" placement="top">
						<el-button type="primary" size="mini" circle icon="el-icon-search" @click="openDrawer(scope.row)"></el-button>
					</el-tooltip>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange" :page-size="search.rows" :current-page="search.page" :total="search.total" />


		<el-drawer v-model="showDrawer" :title="versionInfo.title" direction="rtl" :lock-scroll="false" size="40%">
			<el-form :model="versionInfo" label-width="150px" label-position="left">
				<el-form-item label="适用系统">
					<el-radio-group disabled v-model="versionInfo.os_type" class="ml-4">
						<el-radio :label="1" size="large">安卓</el-radio>
						<el-radio :label="2" size="large">IOS</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="s3文件key">
					<el-input disabled v-model="versionInfo.file_key" type="textarea" />
				</el-form-item>
				<el-form-item label="版本">
					<el-input disabled v-model="versionInfo.version" />
				</el-form-item>
				<el-form-item label="面板名称">
					<el-input disabled v-model="versionInfo.panel_name" />
				</el-form-item>
				<el-form-item label="面板版本">
					<el-input disabled v-model="versionInfo.panel_version" />
				</el-form-item>
				<el-form-item label="最小适用APP版本">
					<el-input disabled v-model="versionInfo.app_version" />
				</el-form-item>
				<el-form-item label="配置内容">
					<el-input disabled v-model="versionInfo.content" />
				</el-form-item>
				<el-form-item label="文件MD5">
					<el-input disabled v-model="versionInfo.md5" />
				</el-form-item>
				<el-form-item label="JsBundle MD5">
					<el-input disabled v-model="versionInfo.jsbundlemd5" />
				</el-form-item>
				<el-form-item label="发布状态">
					<el-radio-group v-model="versionInfo.status" size="large" @change="changeStatus">
						<el-radio-button :label="1">测试</el-radio-button>
						<el-radio-button :label="2">发布</el-radio-button>
						<el-radio-button :label="3">撤回</el-radio-button>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="备注">
					<el-input disabled v-model="versionInfo.notes" />
				</el-form-item>
				<el-form-item label="创建时间">
					<el-date-picker disabled v-model="versionInfo.create_time" type="datetime" />
				</el-form-item>
				<el-form-item label="修改时间">
					<el-date-picker disabled v-model="versionInfo.update_time" type="datetime" />
				</el-form-item>
				<el-form-item label="面板类型">
					<el-input disabled v-model="versionInfo.panel_type" />
				</el-form-item>
				<el-form-item label="ModuleName">
					<el-input disabled v-model="versionInfo.moduleName" />
				</el-form-item>
			</el-form>
		</el-drawer>
	</div>

</template>

<script>
	export default {
		components: {},
		name: 'RnVersions',
		mixins: [],
		data() {
			return {
				tableData: [],
				search: {
					rows: 10,
					page: 1,
					total: 0,
					panel_type: "",
				},
				showDrawer: false,
				versionInfo: {},
			}
		},
		mounted() {
			this.search.panel_type = this.$route.query.panel_type;
			this.getList();
		},
		methods: {
			getList: function() {
				if (this.search.panel_type == "" || this.search.panel_type == undefined) {
					this.$message.error("页面加载错误");
					return;
				}
				this.api.RnPanelVersions(this.search).then(res => {
					if (res.data.code == 200) {
						this.tableData = res.data.data.data;
						this.search.total = res.data.data.page_info.total;
						return
					}
					this.$message.error("请求错误");
				})
			},
			handleCurrentChange: function(val) {
				this.search.page = val;
				this.getList();
			},
			openDrawer: function(val) {

				this.versionInfo = JSON.parse(JSON.stringify(val)); // 深拷贝 避免变量联动

				var osType = "IOS"
				if (this.versionInfo.os_type == 1) {
					osType = "安卓";
				}
				this.versionInfo.create_time = this.versionInfo.create_time * 1000;
				this.versionInfo.update_time = this.versionInfo.update_time * 1000;
				this.versionInfo.title = this.versionInfo.panel_name + "_" + osType + "_" + this.versionInfo.panel_version;
				this.showDrawer = true;
			},
			changeStatus: function(val) {
				this.api.RnPanelUpdateStatus({
					id: this.versionInfo.id,
					status: this.versionInfo.status
				}).then(res => {
					if (res.data.code == 200) {
						this.$message.success("修改成功");
						this.getList();
						return
					}
				})
			}
		},
	}
</script>

<style>
	.el-drawer__body {
		overflow: auto;
		padding: 20px;
	}

	.el-drawer__container ::-webkit-scrollbar {
		display: none;
	}
</style>